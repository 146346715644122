* {
  box-sizing: border-box;
  color: #190495;
  
}


 .card {
  
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

 }
  
 .card-title {  
  width: 100%;
  
  
}
 
 
 .card-title  > h1{  
    max-width: 450px;
    font-size: 40px;  
    color: #0E0644;
    text-align: left;
    
  }

  



  .card-card {
    margin-bottom: 100px;
    max-width: 1100px;
    text-align: center; 
    max-width: 100%;
    border-radius: 25px;
    padding: 30px;
    
    }



  .card-card > p {  
    max-width: 650px;
    font-size: 22px;
    
    font-weight: 500;
    text-align: left;
    margin: auto;
    margin-top: 30px;
    
    
  }


  .flex-card-wrap_1{
    width:100%;
    text-align: right;
    
    
  }


  @media (max-width: 800px) {
    .flex-card-wrap_1{
      width:100%;
      text-align: center;
      
      
      
    }
  }
  



  .flex-card-wrap {
    width:100%;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap:30px;
    
  }


  @media (max-width: 800px) {
    .flex-card-wrap{
      flex-direction: column-reverse;
      display: flex;
      align-items: center;
      
      margin: auto;
      margin-bottom: 100px;

    }
  }







 


