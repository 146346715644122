* {
  box-sizing: border-box;
  color: #190495
}



.flex-container-game {
    
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;  
  margin: auto;
}





  
.flex-container-game-card {
    
  padding:24px
}


  .header {
  
    width:100%;
    padding-left: 24px;
  
  }
  
  
  
  
  
  .footer {
    width:100%;
    padding-left: 24px;
    
  }
  
  

