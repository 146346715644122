.button-1 {
    
    background-color: #1a0495;
    border: none;
    color: white;
    
    text-align: center;
    /* text-decoration: none; */

    font-size: 18px;
    font-weight: 500;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    
    padding: 15px 32px;
    border-radius: 30px;
    
    /* min-width: 240px; */
    
  }


  .button-1:hover {
    
    background-color: white;
    color: #1a0495;

    border-style: solid;
    border-width: 2px;
        
  }


  .button-1:focus {
    
   text-decoration: underline;
  
  }



  .button-2 {



    background-color: #ffffff;
    border: none;
    color: #190495;
    
    text-align: center;
    /* text-decoration: none; */

    font-size: 18px;
    font-weight: 500;
    
    cursor: pointer;
   
    padding: 15px 32px;
    border-radius: 30px;
    border-style: solid;
    border-width: 2px;
  }


  .button-2:hover {
    
    background-color: #1a0495 ;
    color: white;
        
  }


  .button-2:focus {
    
    text-decoration: underline;
   
   }
 