
  
  /* Modal Button */

  
  /* Modal */
  .overlay {
    /* background-color: rgba(0, 0, 0, 0.5); */
    position: fixed;
    width: 100%;
    /* height: 80%; */
    padding:24px;
  }
  
  .modalContainer {
    max-width: 400px;
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #ffffff;
    border: 1px;
    border-color: #0E0644;
    border-style: solid;
    /* box-shadow: 0px 0px 2px 0px #0E0644; */
    border-radius: 24px;
  }
  

  .modalRight {
    width: 100%;
  }
  

  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
    padding: 0.5rem 0.5rem;

  }


  .content > h4{    
    font-size: 24px;
    height: 36;
    color: #0E0644; 

  }

  .content > p{    
    font-size: 24px;
    
    

  }
  
  .btnContainer {
    display: flex;

    padding: .5rem 5rem;
  }
  .btnContainer button {
    width: 100%;
    margin: .5rem;
    padding: 16px 0;

  }
  
  .btnPrimary {
    background-color: #411b57;
    color: white;
  }
  


  @media screen and (max-width: 800px) {
    .modalContainer {
      flex-direction: column;
      
      padding: 24px;
      top: 30%;
      left: 10%;
      transform: none;
      width: 80%;
      /* height: 100vh; */

    }
    }

   
