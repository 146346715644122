.header--nav {    
    
    padding-bottom:50px;
    padding-top:20px;
    width:100%;
    
    

  }


  .header--nav > a > img {    
    
   
    width : "250px";
    

  }


