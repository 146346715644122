

/* style={{overflow: "auto", width: '485px', height: '200px', borderStyle: '2px solid grey' , borderColor: '#190495' , padding: '0px', borderRadius: '25px'}} */

.box-st{
    
    
    /* width:100%!important; */
    
    /* border-style: '2px solid grey'; */

    
    

    


}

    .box-st > textarea{
        
        color:#0E0644;
        
        min-height: 205px;
        width:100%!important;
        
        /* border-style: '2px solid grey'; */
        border-style: solid;
        
        border-radius: 25px;

        /* margin: auto; */
        padding: 10px;

        

        


    }

    .box-st > input:focus{
        

        border-style: solid;


        


    }





/* style={{ overflow: "auto" , width: '100%', height: '100%', borderStyle: '2px solid' , borderColor: '#190495' , padding: '0px' }} */