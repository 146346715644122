* {
  box-sizing: border-box;
  color: #190495
}



  .flex-container_2 {
    
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;  
    margin: auto;
  }
  

  .summary-title {   
    width: 100%; 
    justify-content:flex-start;
    padding-left: 24px;
  }




  .summary-title  > h1 {  
    max-width: 550px;
    font-size: 40px;  
    color: #0E0644;
    text-align: left;
 
  }

  .flex-container_ {

    justify-content: center;
    

  }



@media (max-width: 800px) {
  .flex-container_{
    
    flex-direction: column;
    
    
  }
}


  .summary-points{
    justify-content: center; 
    padding: 24px;
    
  }





  .summary-points > li {  
    display: flex;
    margin: auto ;
    max-width: 1200px;    
    margin-top: 60px;    
    border-radius: 40px;
    padding: 16px;
    align-items: center;
    
  }

  @media (max-width: 800px) {
    .summary-points > li {
      flex-direction: column;
      margin-bottom: 50px;
      padding:20px;
      align-items: center;
      display:flex;

    }
  }

  
  .summary-points-txt > p{  
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
    color: #ffffff;
    

  
  }


  /* .summary-points  > ol > img {  
      max-width: 100px;
    

  
  } */


  @media (max-width: 800px) {
    .summary-points  > ol > img {  
      max-width: 100px;
      
    

  
  }
}


  .flex-summary-nav {
    max-width: 1000px;  
    justify-content: space-between;
    display: flex;
    margin:auto;
    margin-top: 50px;
    padding: 24px;

    
    

  }
  

  /* Responsive layout - makes a one column layout instead of a two-column layout */
  @media (max-width: 800px) {
    .flex-summary-nav {
      
      /* margin: 0px; */
      margin-bottom: 100px;
      gap: 50px;
      flex-direction:column-reverse;
      align-items: center;
    }
  }



  .flex-summary-nav > button {
    margin: auto;
    
    

  }


  .header {
  
    width:100%;
    padding-left: 24px;
  
  }
  
  
  
  
  
  .footer {
    width:100%;
    padding-left: 24px;
    order:3;
  
  }
  
  

