
* {
    box-sizing: border-box;
    color: #190495;
    
  }


  @font-face {
    font-family: myfonts;
    src: url("../../assets/fonts/Helvetica.woff2");
 }
 
 * {
    font-family: myfonts;
 }



  .flex-container {
    flex-wrap:wrap;
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    columns: 2;
    margin-bottom: 180px;
    max-width:1440px;
    margin: auto;
  }
  
  /* Responsive layout - makes a one column layout instead of a two-column layout */
  @media (max-width: 800px) {
    .flex-container {
      flex-direction: column;
      margin: 0px;
      margin-bottom: 100px;
      width:100%;
    }
  }



  .Homepage-main{ 
    width: 50%;
    color: #190495;
    flex-direction: column-reverse;
    order:1;
    max-width: 600px;
    
    
    padding-left:24px;
    padding-right:24px;
    
  
  }



    /* Responsive layout - makes a one column layout instead of a two-column layout */
    @media (max-width: 800px) {
    .Homepage-main{    
        color: #190495;        
        max-width: 600px;
        margin: auto;
        margin-left: auto;
        margin-top: 50px;
        width: 100%;
        order:2;
        

    }
    }



    .Homepage-main > h2{    
            font-size: 30px;
            height: 36;
            margin-bottom: 10px;
            
      
      }
    

      
    .Homepage-main > h1{    
        color: #0E0644;
        font-size: 40px;
        font-weight: 700;
        
        height:120px;        
        max-height: 130px;
        /* min-width: 500px; */

  
  }

            .Homepage-main > h1 >span{    
                    display: block;
                    color: #0E0644;
                   

            }

      .Homepage-main > ol{    
        font-weight: 700;     
        font-size: 22px;
        padding-top: 10px;
        padding-bottom: 30px;
        padding-inline-start: 30px;
       
          
  }


  .Homepage-main > ol >li{    
        
    padding-bottom: 15px;
    
  }

  .Homepage-img{    
    
    display: inline-block;
    order:2;
    width:50%;
    }


    /* Responsive layout - makes a one column layout instead of a two-column layout */
    @media (max-width: 800px) {
    .Homepage-img{    
            color: #190495;
            display: inline-block; 
            margin-right: 0px;
            width:100%;
            order:1;

            

    }
    }


  .Homepage-text{ 
    display: inline-block;
    color: #190495;
    max-width: 1440px;
    align-items: center;
    justify-content: center;
    
  }


  .button-wrap{

    display: flex;
    justify-content:flex-end;
    padding: 60px 0px;


  }

  @media (max-width: 800px) {
    .button-wrap{

      
      justify-content:center;
      
  
  
    }
  }



.header {
  
  width:100%;
  padding-left: 24px;

}





.footer {
  width:100%;
  padding-left: 24px;
  order:3;

}

